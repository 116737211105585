import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "",
        name: 'home',
        component: () => import("../views/Home.vue")
      },
      {
        path: "/role",
        name: "role",
        component: () => import("../views/sys/Role.vue")
      },
      {
        path: "/manager",
        name: "manager",
        component: () => import("../views/sys/Manager.vue")
      },
      {
        path: "/navigation",
        name: "navigation",
        component: () => import("../views/sys/Navigation.vue")
      },
      {
        path: "/siteconfig",
        name: "siteconfig",
        component: () => import("../views/sys/SiteConfig.vue")
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("../views/sys/Banner.vue")
      },
      {
        path: "/articleInfo",
        name: "articleInfo",
        component: () => import("../views/sys/ArticleInfo.vue")
      },
      {
        path: "/articleStatic/:id",
        name: "articleStatic",
        component: () => import("../views/sys/ArticleStatic.vue"),
      },
      {
        path: "/data",
        name: "data",
        component: () => import("../views/sys/Data.vue")
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/sys/Category.vue")
      },
      {
        path: "/userlist",
        name: "userlist",
        component: () => import("../views/user/Userlist.vue")
      },
      {
        path: "/collect_year",
        name: "collect_year",
        component: () => import("../views/collect/Year.vue")
      },
      {
        path: "/collect_day",
        name: "collect_day",
        component: () => import("../views/collect/Month.vue")
      },
      {
        path: "/supcollect_month",
        name: "supcollect_month",
        component: () => import("../views/collect/SupYear.vue")
      },
      {
        path: "/shopGoods",
        name: "shopGoods",
        component: () => import("../views/mall/ShopGoods.vue")
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/mall/Order.vue")
      },
      {
        path: "/comment",
        name: "comment",
        component: () => import("../views/mall/Comment.vue")
      },
      {
        path: "/goodsCate",
        name: "goodsCate",
        component: () => import("../views/mall/GoodsCate.vue")
      },
      {
        path: "/depart",
        name: "depart",
        component: () => import("../views/this/Depart.vue")
      },
      {
        path: "/applyPrice",
        name: "applyPrice",
        component: () => import("../views/this/ApplyPrice.vue")
      },
      {
        path: "/buyerDepart",
        name: "buyerDepart",
        component: () => import("../views/this/BuyerDepart.vue")
      },
      {
        path: "/buyer",
        name: "buyer",
        component: () => import("../views/user/Buyer.vue")
      },
      {
        path: "/supplier",
        name: "supplier",
        component: () => import("../views/user/Supplier.vue")
      },
      {
        path: "/userGoods",
        name: "userGoods",
        component: () => import("../views/user/UserGoods.vue")
      },
      {
        path: "/userOrder",
        name: "userOrder",
        component: () => import("../views/user/UserOrder.vue")
      },
      {
        path: "/relation",
        name: "relation",
        component: () => import("../views/user/Relation.vue")
      },
      {
        path: "/collect_analysis",
        name: "collect_analysis",
        component: () => import("../views/collect/Analysis.vue")
      },
      {
        path: "/ChangeSub",
        name: "ChangeSub",
        component: () => import("../views/user/ChangeSub.vue")
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/middle",
    name: "middle",
    component: () => import("../views/Middle.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
